@import 'vars';

body {
    font-family: $font;
    font-weight: 300;
    color: #666666;
    font-size: 18px;
    line-height: 1.1;
}

h1, h2, h3, h4 {
    font-weight: 700;
}
h1,
h2 {
    color: $red;
}

h2 {
    font-size: 2em;
    margin-top: 0;
}

h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
    border: 0;
}

/** text styles **/
.red {
    color: $red;
}

.blue {
    color: $blue;
}

.green {
    color: $green;
}

.light-green {
    color: $green-light;
}

.button,
a.button:link,
a.button:visited {
    display: inline-block;
    text-decoration: none;
    text-align: center;
    font-size: 20px;
    color: #FFF;
    padding: 10px 20px;
    background-color: $blue;
    line-height: 1;
    font-weight: bold;
    border: 0;
    margin: 5px;
    cursor: pointer;
    -webkit-transition: background-color .3s ease-in-out;
    -moz-transition: background-color .3s ease-in-out;
    -o-transition: background-color .3s ease-in-out;
    transition: background-color .3s ease-in-out;
    box-sizing: border-box;
}

.button:hover,
a.button:hover {
    background-color: #161a60;
}

.button-green,
a.button-green:link,
a.button-green:visited {
    display: inline-block;
    text-decoration: none;
    text-align: center;
    font-size: 20px;
    color: #FFF;
    padding: 10px 20px;
    background-color: $green-light;
    line-height: 1;
    font-weight: bold;
    border: 0;
    margin: 0px 5px;
    cursor: pointer;
    -webkit-transition: background-color .3s ease-in-out;
    -moz-transition: background-color .3s ease-in-out;
    -o-transition: background-color .3s ease-in-out;
    transition: background-color .3s ease-in-out;
}

.button-green:hover,
a.button-green:hover {
    background-color: $green;
}

/* background styles */
.bg-blue,
.bg-dark {
    color: #FFF;
}
.bg-blue {
    background-color: $blue;
    .button,
    a.button {
        color: $red;
        background-color: #FFF;
    }
}
.bg-dark {
    background-color: $navy-dark;
}

/*** blocks ***/
.img-left,
.img-right {
    max-width: 50%;
    margin: 30px;
    margin-top: 0px;
}

.img-left {
    float: left;
    margin-left: 0px;
}

.img-right {
    float: right;
    margin-right: 0px;
}

.spread-items {
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    /*horizontal*/
    align-items: center;
    /*vertical*/
}

.two-col,
.four-col,
.five-col,
.staff {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.two-col,
.four-col,
.five-col {
    padding: 0px 1%;
}

.staff {
    position: relative;
    vertical-align: top;
    padding-left: 32%;
    /* image */
    padding-left: calc(30% + 20px);
    /* image */
    width: 100%;
    min-height: 320px;
}

.staff h3 {
    margin: 0px;
}

.staff h4 {
    margin-top: 5px;
}

.staff img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 30%;
    height: auto !important;
    margin: 0px !important;
}

.two-col {
    width: 48%;
    margin-bottom: 10px;
}

.four-col {
    width: 23%;
}

.five-col {
    width: 18%;
}

.clear {
    display: block;
    width: 100%;
    clear: both;
}


/* ==========================================================================
   Media Queries
 ========================================================================== */

@media only screen and (max-width: 1000px) {
    .spread-items {
        justify-content: space-evenly;
    }
}

@media screen and (max-width:960px) {
    .four-col,
    .five-col {
        width: 48%;
    }
    .staff {
        padding-left: 0px;
        min-height: 0px;
    }
    .staff img {
        position: static;
        display: block;
        max-width: 300px;
        margin: 20px auto !important;
    }
    .staff h1,
    .staff h2,
    .staff h3,
    .staff h4 {
        text-align: center;
    }
}

@media screen and (max-width:760px) {
    .img-left,
    .img-right {
        max-width: 100%;
        float: none;
        margin: 20px 0px;
    }
    .two-col,
    .four-col,
    .five-col {
        width: 100%;
        padding: 0px;
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 400px) {
    h2 {
        font-size: 2em;
        margin: 0.8em 0px;
    }
    h3 {
        font-size: 1.5em;
        margin: 1em 0px;
    }
}